// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfdocumenttemplate from "../../blocks/cfdocumenttemplate/src/Cfdocumenttemplate";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfemailingsystemwithmergetemplates1 from "../../blocks/cfemailingsystemwithmergetemplates1/src/Cfemailingsystemwithmergetemplates1";
import Cfcellcustomization from "../../blocks/cfcellcustomization/src/Cfcellcustomization";
import Cfemailagentapiintegration2 from "../../blocks/cfemailagentapiintegration2/src/Cfemailagentapiintegration2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Cffieldhiding from "../../blocks/cffieldhiding/src/Cffieldhiding";
import RandomNumberGenerator from "../../blocks/randomnumbergenerator/src/RandomNumberGenerator";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import Pagination from "../../blocks/pagination/src/Pagination";
import Sorting from "../../blocks/sorting/src/Sorting";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cfcolorconfigurationbydropdown from "../../blocks/cfcolorconfigurationbydropdown/src/Cfcolorconfigurationbydropdown";
import CreateComment from "../../blocks/comments/src/CreateComment";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Remoteadministration2 from "../../blocks/remoteadministration2/src/Remoteadministration2";
import Reservations from "../../blocks/reservations/src/Reservations";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Cffreezecolumns from "../../blocks/cffreezecolumns/src/Cffreezecolumns";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfdomainconfiguration from "../../blocks/cfdomainconfiguration/src/Cfdomainconfiguration";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Cfsecurefieldforpasswordsssnorothersensitivedata from "../../blocks/cfsecurefieldforpasswordsssnorothersensitivedata/src/Cfsecurefieldforpasswordsssnorothersensitivedata";
import Cfmicrosite from "../../blocks/cfmicrosite/src/Cfmicrosite";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Cfconfigurecustomersubdomains from "../../blocks/cfconfigurecustomersubdomains/src/Cfconfigurecustomersubdomains";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import TimeZoneDetection from "../../blocks/timezonedetection/src/TimeZoneDetection";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Cftogglefiled from "../../blocks/cftogglefiled/src/Cftogglefiled";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Employeelogin from "../../blocks/employeelogin/src/Employeelogin";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfdocumentmerge from "../../blocks/cfdocumentmerge/src/Cfdocumentmerge";
import Analytics from "../../blocks/analytics/src/Analytics";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Cfmultiselectfieldcoloreddropdownsmin2000records from "../../blocks/cfmultiselectfieldcoloreddropdownsmin2000records/src/Cfmultiselectfieldcoloreddropdownsmin2000records";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Slackintegration2 from "../../blocks/slackintegration2/src/Slackintegration2";
import ContactList from "../../blocks/contactlist/src/ContactList";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Cfemailagentapiintegration from "../../blocks/cfemailagentapiintegration/src/Cfemailagentapiintegration";
import Cfcustomizableloginpage1 from "../../blocks/cfcustomizableloginpage1/src/Cfcustomizableloginpage1";
import Cfformviewandedit from "../../blocks/cfformviewandedit/src/Cfformviewandedit";
import Notifications from "../../blocks/notifications/src/Notifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfuicomponentcreation from "../../blocks/cfuicomponentcreation/src/Cfuicomponentcreation";
import Print from "../../blocks/print/src/Print";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Cf3rdpartyforphoneapi from "../../blocks/cf3rdpartyforphoneapi/src/Cf3rdpartyforphoneapi";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Storecontentmanagement2 from "../../blocks/storecontentmanagement2/src/Storecontentmanagement2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Cfcreateview from "../../blocks/cfcreateview/src/Cfcreateview";


const routeMap = {
Cfdocumenttemplate:{
 component:Cfdocumenttemplate,
path:"/Cfdocumenttemplate"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfemailingsystemwithmergetemplates1:{
 component:Cfemailingsystemwithmergetemplates1,
path:"/Cfemailingsystemwithmergetemplates1"},
Cfcellcustomization:{
 component:Cfcellcustomization,
path:"/Cfcellcustomization"},
Cfemailagentapiintegration2:{
 component:Cfemailagentapiintegration2,
path:"/Cfemailagentapiintegration2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Cffieldhiding:{
 component:Cffieldhiding,
path:"/Cffieldhiding"},
RandomNumberGenerator:{
 component:RandomNumberGenerator,
path:"/RandomNumberGenerator"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
Pagination:{
 component:Pagination,
path:"/Pagination"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Cfcolorconfigurationbydropdown:{
 component:Cfcolorconfigurationbydropdown,
path:"/Cfcolorconfigurationbydropdown"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Remoteadministration2:{
 component:Remoteadministration2,
path:"/Remoteadministration2"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Cffreezecolumns:{
 component:Cffreezecolumns,
path:"/Cffreezecolumns"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Cfdomainconfiguration:{
 component:Cfdomainconfiguration,
path:"/Cfdomainconfiguration"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Cfsecurefieldforpasswordsssnorothersensitivedata:{
 component:Cfsecurefieldforpasswordsssnorothersensitivedata,
path:"/Cfsecurefieldforpasswordsssnorothersensitivedata"},
Cfmicrosite:{
 component:Cfmicrosite,
path:"/Cfmicrosite"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Cfconfigurecustomersubdomains:{
 component:Cfconfigurecustomersubdomains,
path:"/Cfconfigurecustomersubdomains"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
TimeZoneDetection:{
 component:TimeZoneDetection,
path:"/TimeZoneDetection"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Cftogglefiled:{
 component:Cftogglefiled,
path:"/Cftogglefiled"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Employeelogin:{
 component:Employeelogin,
path:"/Employeelogin"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cfdocumentmerge:{
 component:Cfdocumentmerge,
path:"/Cfdocumentmerge"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
ThemeBlock:{
 component:ThemeBlock,
path:"/ThemeBlock"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Cfmultiselectfieldcoloreddropdownsmin2000records:{
 component:Cfmultiselectfieldcoloreddropdownsmin2000records,
path:"/Cfmultiselectfieldcoloreddropdownsmin2000records"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Slackintegration2:{
 component:Slackintegration2,
path:"/Slackintegration2"},
ContactList:{
 component:ContactList,
path:"/ContactList"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Cfemailagentapiintegration:{
 component:Cfemailagentapiintegration,
path:"/Cfemailagentapiintegration"},
Cfcustomizableloginpage1:{
 component:Cfcustomizableloginpage1,
path:"/Cfcustomizableloginpage1"},
Cfformviewandedit:{
 component:Cfformviewandedit,
path:"/Cfformviewandedit"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfuicomponentcreation:{
 component:Cfuicomponentcreation,
path:"/Cfuicomponentcreation"},
Print:{
 component:Print,
path:"/Print"},
PasswordStrength:{
 component:PasswordStrength,
path:"/PasswordStrength"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Cf3rdpartyforphoneapi:{
 component:Cf3rdpartyforphoneapi,
path:"/Cf3rdpartyforphoneapi"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Storecontentmanagement2:{
 component:Storecontentmanagement2,
path:"/Storecontentmanagement2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Cfcreateview:{
 component:Cfcreateview,
path:"/Cfcreateview"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;